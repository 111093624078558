.ignite-sidebar .Mui-selected {
    border-right: .4em solid #ff4f1f !important;
}

.ignite-sidebar .MuiListItemButton-root {
    border-right: .4em solid #23395d;
    color: #fff;
    padding-top: 0;
    padding-bottom: 0;
}

.ignite-sidebar .MuiListItemButton-root .MuiListItemButton-root {
    padding-left: 1.5em !important;
}

.ignite-sidebar .MuiListItemButton-root {
    padding-top: 0;
    padding-bottom: 0;
    transition: all 0.5s ease;
}

.ignite-sidebar .MuiCollapse-root .MuiListItemButton-root:hover {
    border-right: .4em solid #ff4f1f !important;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 3.5em !important;
    transition: all 0.5s ease;
}

.ignite-sidebar .MuiListItemButton-root:hover {
    border-right: .4em solid #ff4f1f !important;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 2em !important;
    transition: all 0.5s ease;
}

.ignite-sidebar .MuiListSubheader-root {
    line-height: 32px;
}

.ignite-sidebar .MuiListSubheader-root {
    background-color: inherit;
    color: #fff !important;
    margin-top: 0.5em;
}

.ignite-sidebar .MuiDivider-root {
    border-color: #fff;
}

.ignite-sidebar .MuiSvgIcon-root {
    color: #fff;
}

.ignite-sidebar .MuiSvgIcon-colorSecondary {
    color: #ff4f1f
}

.ignite-sidebar .MuiList-root {
    margin-top: 0em;
    margin-bottom: 0.5em;
}

.ignite-sidebar .MuiTypography-root {
    color: #fff;
}

.ignite-sidebar {
    background-color: #23395d !important;
    color: white !important;
    -webkit-box-shadow: 10px 0px 10px -9px rgba(0,0,0,0.7);
    -moz-box-shadow: 10px 0px 10px -9px rgba(0,0,0,0.7);
    box-shadow: 10px 0px 10px -9px rgba(0,0,0,0.7);
}

.ignite-sidebar a:-webkit-any-link {
    color: white !important;
}

.ignite-sidebar-img {
    max-width: 38px;
    max-height: 54px;
    margin-left: 0.5em;
}

